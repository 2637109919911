import React, { Component } from "react";
import { connect } from "react-redux";
import { configureHeader } from "@actions/ui";
import EditorialGridModule from "@components/modules/editorial/editorial-grid-module";
import { GA } from "@constants/config";
import Footer from "@components/global/footer/footer";
import { META } from "../constants/config";

class Editorial extends Component {
  componentDidMount = () => {
    if (window) {
      window.scrollTo(0, 0);
      window.ga && window.ga("send", "pageview", GA.PAGES.editorial);
    }
    this.props.configureHeader({
      headerVisibilityLocked: true,
      headerTheme: "white",
    });
  };

  render() {
    return (
      <div className="editorial-container">
        <EditorialGridModule
          users={this.props.users}
          posts={this.props.content}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.eventUsers.data,
    content: state.editorial.data,
    browser: state.browser,
    platform: state.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
  };
};

const EditorialRedux = connect(mapStateToProps, mapDispatchToProps)(Editorial);

export default EditorialRedux;

export const Head = () => (
  <>
    <title>{`${META.title} | Editorials`}</title>
    <link
      rel="canonical"
      href={`${process.env.GATSBY_TTF_SITE_URL}/editorial`}
    />
    <meta property="og:title" content={`${META.title} | Editorials`} />
  </>
);
