import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "gatsby";
import moment from "moment-timezone";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { limitString } from "@utils/limit-string";
import { EDITORIAL_TILE_HERO } from "@constants/content-tile-types";
import { get } from "lodash";

class EditorialTile extends Component {
  static propTypes = {
    type: PropTypes.string,
    column: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    body: PropTypes.string,
    author: PropTypes.string,
    users: PropTypes.object,
    id: PropTypes.string,
    updated: PropTypes.string,
  };

  getExcerpt = () => {
    const html = stateToHTML(convertFromRaw(JSON.parse(this.props.body)));
    const div = document.createElement("div");
    div.innerHTML = html;

    return div.textContent || div.innerText || "";
  };

  getPublishingDate = () => {
    const d = moment(this.props.updated).startOf("day");
    return d.format("MMMM") + " " + d.format("DD") + ", " + d.format("YYYY");
  };

  getAuthor = (id) => {
    const authorId = this.props.users.result.find((user) => user === id);

    if (authorId) {
      const author = this.props.users.entities.users[authorId];
      return (
        <Link to={`/u/${author.username}`}>
          {author.first_name} {author.last_name}
        </Link>
      );
    }

    return "";
  };

  render() {
    const { type, column } = this.props;

    const fittedLimit = 40;
    const titleLimit = 50;

    const previewImage =
      get(this.props, "thumbnail.url", null) ||
      get(this.props, "image.url", null);

    return (
      <div className={column} data-type={type}>
        <div
          className={`content-tile ${
            type === EDITORIAL_TILE_HERO
              ? "content-tile--editorial-hero"
              : "content-tile--editorial"
          }`}
        >
          <div className="content-tile__content">
            <Link to={`/editorial/${this.props.id}`}>
              <div className="content-tile__hot-link-area" />
            </Link>
            <Link to={`/editorial/${this.props.id}`}>
              <div className="content-tile__cover">
                {previewImage && <img src={previewImage} alt="" />}
              </div>
            </Link>
            <Link to={`/editorial/${this.props.id}`}>
              <div className="content-tile__info">
                <time className="content-tile__publishing-date">
                  {this.getPublishingDate()}
                </time>
                {this.props.title && this.props.title.length > fittedLimit && (
                  <div className="content-tile__title fitted">
                    <span>{limitString(this.props.title, titleLimit)}</span>
                  </div>
                )}
                {this.props.title && this.props.title.length <= fittedLimit && (
                  <div className="content-tile__title">{this.props.title}</div>
                )}
                <div className="content-tile__description">
                  {limitString(this.getExcerpt(), 100)}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorialTile;
