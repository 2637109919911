import React, { Component, Fragment } from "react";
import EditorialFeaturedModule from "@components/modules/common/top-editorial-module";
import EditorialTile from "@components/global/tiles/editorial-tile";
import { EDITORIAL_TILE } from "@constants/content-tile-types";

class EditorialGridModule extends Component {
  renderPosts = () => {
    const { posts } = this.props;
    const postArr = posts.map((post, i) => {
      return (
        <EditorialTile
          key={String(post.id + i)}
          type={EDITORIAL_TILE}
          column="col s12 m6 l4 xl3"
          users={this.props.users}
          {...post}
        />
      );
    });

    return postArr;
  };

  render() {
    return (
      <Fragment>
        <EditorialFeaturedModule
          posts={this.props.posts}
          users={this.props.users}
        />

        <div className="container container__editorial">
          <div className="row">
            <div className="col s12">
              <div className="heading heading--primary">
                <h1 className="heading__headline">Latest</h1>
                <span className="heading__divider" />
                <p className="heading__copy">
                  Notes from the underground: News, views & recon.
                </p>
              </div>
            </div>
          </div>
          <div className="row row__editorial-content">{this.renderPosts()}</div>
        </div>
      </Fragment>
    );
  }
}

export default EditorialGridModule;
