export function limitString(str, limit) {
  const strippedStr = str.replace(/<br\s*[/]?>/gi, " ");
  let bits = [];
  let i;

  bits = strippedStr.split("");
  if (bits.length > limit) {
    for (i = bits.length - 1; i > -1; --i) {
      if (i > limit) {
        bits.length = i;
      } else if (bits[i] === " ") {
        bits.length = i;
        break;
      }
    }
    bits.push(" ...");
  }
  return bits.join("");
}
