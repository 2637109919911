import PropTypes from "prop-types";
import React, { Component } from "react";

import EditorialFeaturedTile from "@components/global/tiles/editorial-featured-tile";
import { EDITORIAL_TILE } from "@constants/content-tile-types";

class EditorialModule extends Component {
  static propTypes = {
    posts: PropTypes.array,
    users: PropTypes.object,
    squarePreviews: PropTypes.bool,
  };

  renderPosts = () => {
    const { posts, squarePreviews = false } = this.props;

    const totalElements = posts.length > 3 ? 3 : posts.length;
    const selects = [];
    for (let i = 0; i < totalElements; i++) {
      let col = "col s12";
      selects.push(
        <EditorialFeaturedTile
          key={String(posts[i].id + i)}
          type={EDITORIAL_TILE}
          column={col}
          users={this.props.users}
          squarePreviews={squarePreviews}
          {...posts[i]}
        />
      );
    }

    return selects;
  };

  render() {
    return (
      <div className="container__editorial editorial--featured">
        <div className="container">
          <div className="row">
            <div className="col s12 m12 col--no-margin-bottom">
              <div className="row">
                <div className="col s12 col--no-margin-bottom">
                  <div className="split-content__top-divider" />
                </div>
                <div className="col s12 m4 heading heading--secondary">
                  <h2 className="heading__headline">
                    <span>Editorial</span>
                  </h2>
                  <p className="heading__description">
                    Notes from the underground: news, views & reconnaissance.
                  </p>
                </div>
                <div className="col s12 m8 col--no-margin-bottom">
                  <div className="row">{this.renderPosts()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorialModule;
